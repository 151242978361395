import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonAlert, useIonLoading, useIonViewWillEnter } from '@ionic/react';
import React, { useRef, useState } from 'react';
import Table from 'react-bootstrap/Table';
import toast, { Toaster } from 'react-hot-toast';
import { connect } from '../data/connect';
import Fetch from '../util/Fetch';
import Permission from '../util/Permission';
import { syncOutline, trashBinOutline } from 'ionicons/icons';
import { Button, Modal } from 'react-bootstrap';

interface OwnProps {
}

interface StateProps {
}

interface DispatchProps {
}

interface SessionListProps extends OwnProps, StateProps, DispatchProps { };

const MachineStorageInfo: React.FC<SessionListProps> = () => {
	const [machineStorageData, setMachineStorageData] = useState<any[]>([]);
	const [showLoading, hideLoading] = useIonLoading();
	const [showIonAlert] = useIonAlert();
	const [selectedMachineId, setSelectedMachineId] = useState(0);

	const [machineData, setMachineData] = useState<any>({});
	const [showMachineDataModal, setShowMachineDataModal] = useState(false);

	let fetchData = () => {
		let syncHistoryQuery = "{machineStorageData{idHrFpMachines,ipAddress,workStationName,currentUserCount,maxUserCount,currentFingerprintCount,maxFingerprintCount,currentFaceCount,maxFaceCount,currentAttendanceCount,maxAttendanceCount,timeDifference,online}}";

		Fetch(syncHistoryQuery).then(data => {
			setMachineStorageData(data.data.machineStorageData);
			// setSyncHistorySearchData(data.data.machineStorageData);
		});
	}

	let userInfo = JSON.parse(window.localStorage.getItem("hrFpUserInfo") || "{}");

	let checkPermission = async () => {
		await Permission(userInfo.idUsers, "machine_storage_info").then(data => {
			if (!data.data.singlePagePermission.idHrFpUsersPermission) {
				toast.error("Access denied.");
				window.location.href = "/machines";
			}
		});
	}

	useIonViewWillEnter(async () => { 
		await checkPermission();
		// await checkSyncPermission();
		fetchData();
	});

	let saveMachineData = () => {
		showLoading();
		let syncQuery = `mutation{
			saveMachineData(idHrFpMachines:${selectedMachineId},
				users:${machineData.current_user_count}, 
				maxUser:${machineData.max_user_count}, 
				fingerprints: ${machineData.current_finger_count},
				maxFingerprint: ${machineData.max_finger_count},
				faces: ${machineData.current_face_count},
				maxFace: ${machineData.max_face_count},
				attendance: ${machineData.current_attendance_count},
				maxAttendance: ${machineData.max_attendance_count},
				systemTime: "${machineData.current_system_time}"
			){
				success
				message
			}
		}`

		Fetch(syncQuery).then(async data => {
			if (data.data.saveMachineData.success) {
				fetchData()
				showIonAlert({
					header: "Success",
					message: "Machine data saved successfully",
					buttons: ["Ok"]
				})
			} else {
				showIonAlert({
					header: "Error",
					message: data.data.saveMachineData.message,
					buttons: ["Ok"]
				})
			}
			hideLoading()
		});
	}

	let handleSubmitSaveMachineData = () => {
		setShowMachineDataModal(false)
		saveMachineData();
	}

	let getMachineData = (idHrFpMachines: number) => {
		showLoading();
		let syncQuery = `query{
			machineData(idHrFpMachines:${idHrFpMachines}){
				success
				message
			}
		}`

		Fetch(syncQuery).then(async data => {
			if (data.data.machineData.success) {
				setMachineData(JSON.parse(data.data.machineData.message));
				setShowMachineDataModal(true);
				setSelectedMachineId(idHrFpMachines)
			} else {
				showIonAlert({
					header: "Error",
					message: data.data.machineData.message,
					buttons: ["Ok"]
				})
			}
			hideLoading()
		});
	}

	const clearAttendance = (idHrFpMachines: number) => {
		showIonAlert({
			header: "Confirmation",
			message: "Are you sure you want to clear attendance logs?",
			buttons: [
				{
					text: "Cancel",
					role: "cancel",
					handler: () => {
						console.log("Action canceled");
					}
				},
				{
					text: "Yes",
					handler: () => {
						showLoading();
						let syncQuery = `mutation{
							clearAttendanceLog(idHrFpMachines:${idHrFpMachines}){
								success
								message
							}
						}`;
	
						Fetch(syncQuery).then(async data => {
							if (data.data.clearAttendanceLog.success) {
								fetchData();
								showIonAlert({
									header: "Success",
									message: "Machine data saved successfully",
									buttons: ["Ok"]
								});
							} else {
								showIonAlert({
									header: "Error",
									message: data.data.clearAttendanceLog.message,
									buttons: ["Ok"]
								});
							}
							hideLoading();
						});
					}
				}
			]
		});
	};

	const pageRef = useRef<HTMLElement>(null);

	

	return (
		<IonPage ref={pageRef} id="machine-sync-history">
			<Toaster position="top-right" reverseOrder={false}/>
			<IonHeader translucent={true}>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Machines Sync History</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div className="table-responsive">
					<Table bordered hover size='sm'>
						<thead>
							<tr>
								<th>IP Address</th>
								<th style={{textAlign:"right"}}>Users</th>
								<th style={{textAlign:"right"}}>Fingerprints</th>
								<th style={{textAlign:"right"}}>Faces</th>
								<th style={{textAlign:"right"}}>Attendances</th>
								<th style={{textAlign:"right"}}>Time Difference(m)</th>
								<th>Sync</th>
							</tr>
						</thead>
						<tbody>
							{machineStorageData ? (machineStorageData.map((value, index) => (
								<tr key={index} >
									<td>{value.ipAddress}</td>
									<td style={{textAlign:"right"}}>{value.currentUserCount && value.currentUserCount.toLocaleString()}/{value.maxUserCount && value.maxUserCount.toLocaleString()}</td>
									<td style={{textAlign:"right"}}>{value.currentFingerprintCount && value.currentFingerprintCount.toLocaleString()}/{value.maxFingerprintCount && value.maxFingerprintCount.toLocaleString()}</td>
									<td style={{textAlign:"right"}}>{value.currentFaceCount && value.currentFaceCount.toLocaleString()}/{value.currentFaceCount && value.maxFaceCount.toLocaleString()}</td>
									<td style={{textAlign:"right"}}>{value.currentAttendanceCount && value.currentAttendanceCount.toLocaleString()}/{value.maxAttendanceCount && value.maxAttendanceCount.toLocaleString()}</td>
									<td style={{textAlign:"right"}}>{value.timeDifference && value.timeDifference.toLocaleString()}</td>
									<td>
									{value.online ? 
									<>
										<IonButton size='small' onClick={() => getMachineData(value.idHrFpMachines)} title="Sync">
											<IonIcon slot="icon-only" icon={syncOutline}></IonIcon>
										</IonButton>
										<IonButton color='danger' size='small' onClick={() => clearAttendance(value.idHrFpMachines)} title="Clear">
											<IonIcon slot="icon-only" icon={trashBinOutline}></IonIcon>
										</IonButton>
									</> : null }
									</td>
								</tr>
							))) : (<tr><td colSpan={7}>No data</td></tr>)}
						</tbody>
					</Table>
				</div>
			</IonContent>

			{/* <Modal show={showModal} onHide={handleClose}>
				<Modal.Header closeButton>
				<Modal.Title>Pending Attendance</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h4>Total {attendanceMessage}</h4>
					<h5>Do you want to sync pending attendance?</h5>
				</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
				<Button variant="primary" onClick={handleSubmitSyncData}>
					Yes, Sync
				</Button>
				</Modal.Footer>
			</Modal> */}

			<Modal show={showMachineDataModal} onHide={() => setShowMachineDataModal(false)}>
				<Modal.Header closeButton>
				<Modal.Title>Machine Data</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<table className="table">
						<thead>
							<tr>
								<th colSpan={2}>Machine Data</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>Current User Count</th>
								<td>{machineData.current_user_count}</td>
							</tr>
							<tr>
								<th>Max User Count</th>
								<td>{machineData.max_user_count}</td>
							</tr>
							<tr>
								<th>Current Fingerprint Count</th>
								<td>{machineData.current_finger_count}</td>
							</tr>
							<tr>
								<th>Max Fingerprint Count</th>
								<td>{machineData.max_finger_count}</td>
							</tr>
							<tr>
								<th>Current Face Count</th>
								<td>{machineData.current_face_count}</td>
							</tr>
							<tr>
								<th>Max Face Count</th>
								<td>{machineData.max_face_count}</td>
							</tr>
							<tr>
								<th>Current Attendance Count</th>
								<td>{machineData.current_attendance_count}</td>
							</tr>
							<tr>
								<th>Max Attendance Count</th>
								<td>{machineData.max_attendance_count}</td>
							</tr>
							<tr>
								<th>System Time</th>
								<td>{machineData.current_system_time}</td>
							</tr>
						</tbody>
					</table>
				</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={() => setShowMachineDataModal(false)}>
					Close
				</Button>
				<Button variant="primary" onClick={handleSubmitSaveMachineData}>
					Save Machine Data
				</Button>
				</Modal.Footer>
			</Modal>
		</IonPage>
	);
};

export default connect<OwnProps, {}, DispatchProps>({
	mapDispatchToProps: {
	},
	component: MachineStorageInfo
})
