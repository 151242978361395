import React, { useEffect } from 'react';
import { RouteComponentProps, useLocation, withRouter } from 'react-router';

import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonToggle } from '@ionic/react';
import * as iconName from 'ionicons/icons';

import { connect } from '../data/connect';
import { setDarkMode } from '../data/user/user.actions';

import './Menu.css';

// let userInfo = JSON.parse(window.localStorage.getItem("hrFpUserInfo") || "{}");

let userMenu = JSON.parse(window.localStorage.getItem("hrFpUserMenu") || "[]");

const routes = {
	appPages: userMenu,
	loggedInPages: [
		{ title: 'Logout', path: '/logout', icon: iconName.logOut }
	]
};

interface Pages {
	title: string,
	path: string,
	icon: string,
	routerDirection?: string
}
interface StateProps {
	darkMode: boolean;
	isAuthenticated: boolean;
	menuEnabled: boolean;
}

interface DispatchProps {
	setDarkMode: typeof setDarkMode
}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps { }

const Menu: React.FC<MenuProps> = ({ darkMode, history, isAuthenticated, setDarkMode, menuEnabled }) => {
	const location = useLocation();

	useEffect(() => {
		
	}, []);

	function renderlistItems(list: Pages[]) {
		return list
			.filter(route => !!route.path)
			.map(p => (
				<IonMenuToggle key={p.title} auto-hide="false">
					<IonItem detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
						<IonIcon slot="start" icon={p.icon} />
						<IonLabel>{p.title}</IonLabel>
					</IonItem>
				</IonMenuToggle>
			));
	}

	return (
		<IonMenu type="overlay" disabled={!menuEnabled} contentId="main">
			<IonContent forceOverscroll={false}>
				<IonList lines="none">
					<IonListHeader>Menu</IonListHeader>
					{/* {renderlistItems(routes.appPages)} */}
					{routes.appPages.map((p: any) => (
						p.title ? <IonMenuToggle key={p.title} auto-hide="false">
							<IonItem detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
								<IonIcon slot="start" icon={iconName[p.icon as keyof typeof iconName]} />
								<IonLabel>{p.title}</IonLabel>
							</IonItem>
						</IonMenuToggle> : null
					)) }
					{/* <IonMenuToggle key='machine-storage-info' auto-hide="false">
						<IonItem detail={false} routerLink='/machine-storage-info' routerDirection="none">
							<IonIcon slot="start" icon={iconName.disc} />
							<IonLabel>Machine Storage Info</IonLabel>
						</IonItem>
					</IonMenuToggle> */}
				</IonList>
				<IonList lines="none">
					<IonListHeader>Account</IonListHeader>
					{isAuthenticated ? renderlistItems(routes.loggedInPages) : null}
					<IonItem>
						<IonIcon slot="start" icon={iconName.moonOutline}></IonIcon>
						<IonLabel>Dark Mode</IonLabel>
						<IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />
					</IonItem>
				</IonList>
			</IonContent>
		</IonMenu>
	);
};

export default connect<{}, StateProps, {}>({
	mapStateToProps: (state) => ({
		darkMode: state.user.darkMode,
		isAuthenticated: state.user.isLoggedin,
		menuEnabled: state.data.menuEnabled
	}),
	mapDispatchToProps: ({
		setDarkMode
	}),
	component: withRouter(Menu)
})
