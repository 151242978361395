import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import 'bootstrap/dist/css/bootstrap.min.css';

/* Theme variables */
import HomeOrTutorial from './components/HomeOrTutorial';
import RedirectToLogin from './components/RedirectToLogin';
import { AppContextProvider } from './data/AppContext';
import { connect } from './data/connect';
import { loadConfData } from './data/sessions/sessions.actions';
import { loadUserData, setIsLoggedIn, setUsername } from './data/user/user.actions';
import { Schedule } from "./models/Schedule";
import AccessControl from './pages/AccessControl';
import AddMachine from './pages/AddMachine';
import AllMachineSyncHistory from './pages/AllMachineSyncHistory';
import ImportUser from './pages/ImportUser';
import Login from './pages/Login';
import MachineDetails from './pages/MachineDetails';
import MachineList from './pages/MachineList';
import './theme/variables.css';
import MachineStorageInfo from './pages/MachineStorageInfo';

const App: React.FC = () => {
	return (
		<AppContextProvider>
			<IonicAppConnected />
		</AppContextProvider>
	);
};

interface StateProps {
	darkMode: boolean;
	schedule: Schedule;
}

interface DispatchProps {
	loadConfData: typeof loadConfData;
	loadUserData: typeof loadUserData;
	setIsLoggedIn: typeof setIsLoggedIn;
	setUsername: typeof setUsername;
}

interface IonicAppProps extends StateProps, DispatchProps { }

const IonicApp: React.FC<IonicAppProps> = ({ darkMode, schedule, setIsLoggedIn, setUsername, loadConfData, loadUserData }) => {

	useEffect(() => {
		loadUserData();
		loadConfData();
		// eslint-disable-next-line
	}, []);

	return (
		schedule.groups.length === 0 ? (
			<div></div>
		) : (
			<IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
				<IonReactRouter>
					<IonSplitPane contentId="main">
						<Menu />
						<IonRouterOutlet id="main">
							<Route path="/machines" render={() => <MachineList />} />
							<Route path="/machine/:id" component={MachineDetails} />
							<Route path="/machineSyncHistory" component={AllMachineSyncHistory} />
							<Route path="/login" component={Login} />
							<Route path="/addMachine" component={AddMachine} />
							<Route path="/importUser" component={ImportUser} />
							<Route path="/manageAccessControl" component={AccessControl} />
							<Route path="/machine-storage-info" component={MachineStorageInfo} />
							<Route path="/logout" render={() => {
								window.localStorage.removeItem('hrFpUserMenu');
								window.localStorage.removeItem('hrFpUserInfo');
								window.localStorage.removeItem('token');
								return <RedirectToLogin
									setIsLoggedIn={setIsLoggedIn}
									setUsername={setUsername}
								/>;
							}} />
							<Route path="/" component={HomeOrTutorial} exact />
						</IonRouterOutlet>
					</IonSplitPane>
				</IonReactRouter>
			</IonApp>
		)
	)
}

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
	mapStateToProps: (state) => ({
		darkMode: state.user.darkMode,
		schedule: state.data.schedule
	}),
	mapDispatchToProps: { loadConfData, loadUserData, setIsLoggedIn, setUsername },
	component: IonicApp
});
